import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getService, reset } from "../../redux/services/services.slices";
import { useDispatch, useSelector } from "react-redux";
import EditServiceModal from "../../Components/services/EditServiceModal";
import { toast } from "react-toastify";

export default function ServiceDetails() {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getService(id));
  }, []);

  const { isLoading, service, message, isSuccess } = useSelector(
    (state) => state.services
  );

  useEffect(() => {
    if (isSuccess) {
      setShowEdit(false);
      toast.success(message);
      dispatch(reset());
      dispatch(getService(id));
    }
  }, [dispatch, isSuccess, message]);

  const handleEdit = (service) => {
    setShowEdit(true);
  };

  return (
    <div className="page-content">
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <Container fluid>
          <BreadCrumb title="Service's Detail" pageTitle="Services" />
          <div className="my-3 d-flex justify-content-between">
            <Link to={"/services"}>Go back</Link>
            {/* <button onClick={() => {}} className="btn btn-info">
            <i className="ri-pencil-text-line"></i>
          </button> */}

            <Button
              color={service?.publish ? "success" : "secondary"}
              size="sm"
              className="me-2 btn-sm btn"
              onClick={handleEdit}
            >
              <i
                className={`ri-toggle-${
                  service?.publish ? "fill" : "line"
                } fw-bold fs-16`}
              ></i>
            </Button>
          </div>

          <Card>
            <CardHeader className="bg-soft-dark">
              <h2 className="my-3 text-primary">
                {service?.name}'s <span>details</span>{" "}
              </h2>
            </CardHeader>
            <CardBody>
              <Row>
                {/* <Col lg="4">
                  <h4>Service ID</h4>
                  <p className="text-muted">{service?._id}</p>
                </Col> */}

                <Col lg="4">
                  <h4>Amount</h4>
                  <p className="text-muted">${service?.amount}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      )}
      {!isLoading && service && (
        <EditServiceModal
          show={showEdit}
          onCloseClick={() => setShowEdit(!showEdit)}
          service={service}
        />
      )}
    </div>
  );
}
