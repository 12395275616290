import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "leads/";

// POST new Lead
const addLead = async (token, lead) => {
  const res = await axios.post(API_URL, lead, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

// GET all Lead
const getAllLeads = async (token, q) => {
  const { page, limit, search } = q;
  const res = await axios.get(
    API_URL + `?page=${page}&limit=${limit}&search=${search}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};

// GET all cancelled Lead
const getCancelledLeads = async (token) => {
  const res = await axios.get(API_URL + "cancelled", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};

// GET an Lead
const getLead = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

// GET admin Lead graph
const getAdminLeadGraph = async (token, year) => {
  let fd = new FormData();
  fd.append("year", year);

  const res = await axios.post(
    process.env.REACT_APP_API_URL + "dashboard/admin",
    fd,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};

// Delete a lead
const deleteLead = async (id, token) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// UPDATE an Lead
const updateLead = async (lead, id, token) => {
  const res = await axios.patch(API_URL + id, lead, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

const LeadService = {
  addLead,
  getAllLeads,
  getCancelledLeads,
  getLead,
  updateLead,
  deleteLead,
  getAdminLeadGraph,
};

export default LeadService;
