import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "clients/";

// POST new client
const addClient = async (token, client) => {
  const res = await axios.post(API_URL, client, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

// GET all client
const getAllClients = async (token, q) => {
  const { page, limit, search } = q;

  const res = await axios.get(
    API_URL + `?page=${page}&limit=${limit}&search=${search}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};

// GET all cancelled client
const getCancelledClients = async (token) => {
  const res = await axios.get(API_URL + "cancelled", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};

// GET an client
const getClient = async (token, id) => {
  const res = await axios.get(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

// GET admin client graph
const getAdminClientGraph = async (token, year) => {
  let fd = new FormData();
  fd.append("year", year);

  const res = await axios.post(
    process.env.REACT_APP_API_URL + "dashboard/admin",
    fd,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};

// Delete a client
const deleteClient = async (id, token) => {
  const res = await axios.delete(API_URL + id, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

// UPDATE an client
const updateClient = async () => {};

const clientService = {
  addClient,
  getAllClients,
  getCancelledClients,
  getClient,
  updateClient,
  deleteClient,
  getAdminClientGraph,
};

export default clientService;
