import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "addendum/";

// CREATE ADDENDUM
// ENDPOINT "/api/v1/addendums"
// DESC create a addendum
const createAddendum = async (addendum, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, addendum, config);
  return response.data;
};

const addendumService = {
  createAddendum,
};

export default addendumService;
