import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updateLead, reset as resetLead } from "../../redux/leads/lead.slices";
import { getAllPackage } from "../../redux/packages/package.slices";
import { getAllManagers } from "../../redux/managers/managers.slices";
import { getAllservice } from "../../redux/services/services.slices";
import moment from "moment";
import DatePicker from "react-date-picker";

const EditClientModal = ({ show, onCloseClick, lead }) => {
  const dispatch = useDispatch();

  // const date = new Date();
  // const maxDate = moment(date.setFullYear(date.getFullYear() - 18)).format(
  //   "YYYY-MM-DD"
  // );

  // const minDate = moment(date.setMonth(date.getMonth() + 1)).format(
  //   "YYYY-MM-DD"
  // );

  const { packages } = useSelector((state) => state.packages);
  const { managers } = useSelector((state) => state.managers);
  const { services } = useSelector((state) => state.services);
  const { user } = useSelector((state) => state.auth);

  const { isError, message, isLoading, isSuccess } = useSelector(
    (state) => state.leads
  );

  const [recurringDate, setRecurringDate] = React.useState(lead?.billing_date);
  const [onetimeValue, setonetimeValue] = useState(null);
  const [fees, setFees] = useState([]);
  const [feeAmount, setFeeAmount] = useState(0);
  const [feeName, setFeeName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [service_date, setServiceDate] = useState(
    moment(lead?.service_date).locale("en").format("YYYY-MM-DD")
  );

  const [dob, setDob] = useState(
    moment(lead?.dob).locale("en").format("YYYY-MM-DD")
  );
  const [secondary_dob, setSecondaryDob] = useState(
    lead?.secondary_dob &&
      moment(lead?.secondary_dob).locale("en").format("YYYY-MM-DD")
  );

  useEffect(() => {
    dispatch(getAllPackage());
    dispatch(getAllManagers());
    dispatch(getAllservice());
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message);
      reset();
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      onCloseClick();
      dispatch(resetLead());
    }
  }, [isSuccess]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      paymentMethod: lead?.paymentMethod,
      firstname: lead?.firstname,
      lastname: lead?.lastname,
      email: lead?.email,
      street: lead?.street,
      city: lead?.city,
      zipCode: lead?.zipCode,
      state: lead?.state,
      contract_length: lead?.contract_length,
      billing_date: lead?.billing_date,
      home_ownership: lead?.home_ownership,
      credit_grade: lead?.credit_grade,
      installer: lead?.installer?._id,
      manager: lead?.manager?._id,
      dob: lead?.dob,
      service_date: lead?.service_date,
      secondary_dob: lead?.secondary_dob,
      secondary_name: lead?.secondary_name,
      secondary_phone: lead?.secondary_phone,
    },
  });

  // useEffect(() => {
  //   if (lead) {
  //     // Set the default dates
  //     setValue("dob", lead?.dob);
  //     setValue("service_date", lead?.service_date);
  //     setValue("secondary_dob", lead?.secondary_dob);
  //     convertDateInput();
  //   }
  // }, [lead, setValue]);

  const handleSave = (data) => {
    dispatch(
      updateLead({
        id: lead?._id,
        data: {
          ...data,
          package: lead?.package,
          onetime_fees: lead?.onetime_fees,
          billing_date: recurringDate,
          paymentMethod: lead?.paymentMethod,
          service_date,
          dob,
          secondary_dob,
        },
      })
    );
  };

  const preventAlphabets = (event) => {
    // Allow only numbers, backspace, delete, plus and minus keys
    if (
      !(
        (
          event.keyCode === 8 || // Backspace
          event.keyCode === 46 || // Delete
          event.keyCode === 43 || // Plus
          event.keyCode === 45 || // Minus
          event.shiftKey ||
          (event.keyCode >= 48 && event.keyCode <= 57) || // Numbers 0-9
          (event.keyCode >= 96 && event.keyCode <= 105)
        ) // Numpad numbers
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size="lg">
      <ModalBody className="py-3 px-5">
        <form onSubmit={handleSubmit(handleSave)}>
          <div className="mt-2">
            <h3 className="mb-4 text-muted mx-auto w-100 text-center">
              Edit Existing Lead
            </h3>
            <h4 className="mb-4 text-info">Primary Account Holder</h4>
            <Row className="row-cols-lg-2">
              <FormGroup>
                <label htmlFor="firstname">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  className="form-control"
                  defaultValue={lead?.firstname}
                  {...register("firstname")}
                />
                {errors.firstname && (
                  <span className="text-danger">First name is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="lastname">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  className="form-control"
                  defaultValue={lead?.lastname}
                  {...register("lastname")}
                />
                {errors.lastname && (
                  <span className="text-danger">Last name is required</span>
                )}
              </FormGroup>

              <FormGroup>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  defaultValue={lead?.email}
                  {...register("email")}
                />
                {errors.email && (
                  <span className="text-danger">Email is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  onKeyDown={preventAlphabets}
                  defaultValue={lead?.phone}
                  {...register("phone")}
                />
                {errors.phone && (
                  <span className="text-danger">Phone is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="street">Street</label>
                <input
                  type="text"
                  name="street"
                  className="form-control"
                  defaultValue={lead?.street}
                  {...register("street")}
                />
                {errors.street && (
                  <span className="text-danger">Street is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  defaultValue={lead?.city}
                  {...register("city")}
                />
                {errors.city && (
                  <span className="text-danger">City is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  name="state"
                  className="form-control"
                  defaultValue={lead?.state}
                  {...register("state")}
                />
                {errors.state && (
                  <span className="text-danger">State is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="zipCode">Zipcode</label>
                <input
                  type="text"
                  name="zipCode"
                  className="form-control"
                  defaultValue={lead?.zipCode}
                  {...register("zipCode")}
                />
                {errors.zipCode && (
                  <span className="text-danger">ZipCode is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="zipCode">Payment method</label>
                <div className="d-flex align-items-center gap-3">
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="paymentMethod"
                      disabled
                      className="form-check me-3"
                      value={"card"}
                      checked={lead?.paymentMethod === "card"}
                    />
                    Credit card
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="paymentMethod"
                      disabled
                      className="form-check me-3"
                      value={"ach"}
                      checked={lead?.paymentMethod === "ach"}
                    />
                    ACH
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="paymentMethod"
                      disabled
                      className="form-check me-3"
                      value={"both"}
                      checked={lead?.paymentMethod === "both"}
                    />
                    Both
                  </div>
                </div>

                {errors.paymentMethod && (
                  <span className="text-danger">
                    Payment method is required
                  </span>
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="contract_length">Contract length</label>
                <input
                  type="number"
                  name="contract_length"
                  className="form-control"
                  defaultValue={lead?.contract_length}
                  {...register("contract_length")}
                  disabled
                />
                {errors.contract_length && (
                  <span className="text-danger">
                    Contract length is required
                  </span>
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="recurring_date">Recurring Billing Date</label>
                <select
                  type="text"
                  name="billing_date"
                  className="form-select"
                  defaultValue={lead?.billing_date}
                  // {...register("billing_date")}
                  disabled
                >
                  <option value={lead?.billing_date}>
                    {lead?.billing_date}
                  </option>
                </select>
                {/* <Select
                  components={animatedComponents}
                  isMulti={false}
                  name="users"
                  className="basic-multi-select"
                  placeholder="select a day for recurring payment..."
                  classNamePrefix="select"
                  closeMenuOnSelect={true}
                  options={daysOption}
                  value={recurringDate}
                  onChange={(e) => setRecurringDate(e)}
                /> */}
                {recurringDate === null && (
                  <span className="text-danger">
                    Recurring Billing Date is required
                  </span>
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="home_ownership">Home ownership</label>
                <select
                  type="text"
                  name="home_ownership"
                  className="form-select"
                  defaultValue={lead?.home_ownership}
                  {...register("home_ownership")}
                >
                  <option value={lead?.home_ownership}>
                    {lead?.home_ownership}
                  </option>
                </select>
              </FormGroup>

              <FormGroup className="col-md-6">
                <label htmlFor="credit_grade">Credit Box</label>
                <select
                  type="text"
                  name="credit_grade"
                  className="form-select"
                  defaultValue={lead?.credit_grade}
                  {...register("credit_grade")}
                >
                  <option value={lead?.credit_grade}>
                    {lead?.credit_grade}
                  </option>
                  <option value={"A"}>A</option>
                  <option value={"B"}>B</option>
                  <option value={"C"}>C</option>
                  <option value={"D"}>D</option>
                  <option value={"F"}>F</option>
                  <option value={"Z"}>Z</option>
                </select>
                {errors.credit_grade && (
                  <span className="text-danger">Credit Box is required</span>
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="package">Package</label>
                <select
                  type="text"
                  name="package"
                  className="form-select"
                  defaultValue={lead?.package?._id}
                  disabled
                  // {...register("package")}
                >
                  <option value={lead?.package?._id}>
                    {lead?.package?.name} - ${lead?.package?.amount}
                  </option>

                  {packages.map((p) => (
                    <option value={p._id}>
                      {p.name}-(${p.amount})
                    </option>
                  ))}
                </select>
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="manager">Manager</label>
                <select
                  type="text"
                  name="manager"
                  className="form-select"
                  defaultValue={lead?.manager?._id}
                  {...register("manager")}
                >
                  <option value={lead?.manager?._id}>
                    {lead?.manager?.firstname} {lead?.manager?.lastname}
                  </option>
                  {managers.map((m) => (
                    <option value={m._id}>
                      {m.firstname} {m.firstname}
                    </option>
                  ))}
                </select>
                {errors.manager && (
                  <span className="text-danger">Manager is required</span>
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="installer">Installer</label>
                <select
                  type="text"
                  name="installer"
                  className="form-select"
                  defaultValue={lead?.installer?._id}
                  {...register("installer")}
                >
                  <option value={lead?.installer?._id}>
                    {lead?.installer?.firstname} {lead?.installer?.lastname}
                  </option>
                  {managers.map((m) => (
                    <option value={m._id}>
                      {m.firstname} {m.firstname}
                    </option>
                  ))}
                </select>
                {errors.installer && (
                  <span className="text-danger">installer is required</span>
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="one_time_fee">One time fee</label>
                <select
                  type="text"
                  name="one_time_fee"
                  className="form-select"
                  value={onetimeValue}
                  onChange={(e) => setonetimeValue(e.target.value)}
                  disabled
                >
                  <option value="">
                    {lead?.onetime_fees?.length > 0 &&
                      "Total - $" +
                        lead?.onetime_fees?.reduce(
                          (acc, curr) => (acc += +curr?.feeAmount),
                          0
                        )}
                  </option>
                  {services.map((serv) => (
                    <option value={`${serv?.name}-${serv?.amount}`}>
                      {serv.name}
                    </option>
                  ))}
                  {user && user?.isAdmin && (
                    <option value={"Others"}>Others</option>
                  )}
                </select>
              </FormGroup>
              {onetimeValue === "Others" && (
                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                  centered={true}
                  size="md"
                >
                  <ModalBody className="py-3 px-5">
                    <ModalHeader>
                      <h3 className="text-muted">Add Custom fees</h3>
                    </ModalHeader>
                    {fees.length !== 0 && (
                      <div className="my-4">
                        <div className="row fw-bolder">
                          <Col>Name</Col>
                          <Col>Amount</Col>
                          <Col>Action</Col>
                        </div>
                        <hr />
                        {fees.map((f, i) => (
                          <div key={i} className="row">
                            <Col>{f.feeName}</Col>
                            <Col>{f.feeAmount}</Col>
                            <Col>
                              <button
                                onClick={() => {
                                  setFees([
                                    ...fees.filter(
                                      (fe) => fe.feeName !== f.feeName
                                    ),
                                  ]);
                                }}
                                className="text-danger"
                              >
                                X
                              </button>
                            </Col>
                          </div>
                        ))}
                      </div>
                    )}

                    <>
                      <hr />

                      <FormGroup>
                        <label htmlFor="fee_name">Fee name</label>
                        <input
                          type="text"
                          name="fee_name"
                          className="form-control"
                          value={feeName}
                          onChange={(e) => setFeeName(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="fee_amount">Fee Amount</label>
                        <input
                          type="number"
                          name="fee_amount"
                          className="form-control"
                          value={feeAmount}
                          onChange={(e) => setFeeAmount(e.target.value)}
                        />
                      </FormGroup>
                      <button
                        onClick={() => {
                          setFees([...fees, { feeName, feeAmount }]);
                          setFeeAmount(0);
                          setFeeName("");
                        }}
                        className="btn btn-success"
                      >
                        Add
                      </button>
                      <button
                        onClick={() => setShowModal(!showModal)}
                        className="btn btn-outline-dark ms-5"
                      >
                        Done
                      </button>
                    </>
                  </ModalBody>
                </Modal>
              )}
              <FormGroup>
                <label htmlFor="notes">Notes</label>
                <textarea
                  name="notes"
                  className="form-control"
                  defaultValue={lead?.notes}
                  {...register("notes")}
                ></textarea>
                {errors.notes && (
                  <span className="text-danger">Notes is required</span>
                )}
              </FormGroup>

              <FormGroup className="col-md-6 position-relative">
                <label htmlFor="dob">Date of birth</label>
                <DatePicker
                  // maxDate={maxDate}
                  className="form-control"
                  value={dob}
                  onChange={(date) => setDob(date)}
                />
                {/* <input
                  type="date"
                  data-date-format="MM/DD/YYYY"
                  data-date=""
                  className="form-control  date-input"
                  placeholder="Date of birth"
                  max={maxDate}
                  value={service_date}
                  onChange={(e) => setServiceDate(e.target.value)}
                  // {...register("dob")}
                /> */}
                {/* {errors.dob && (
                  <span className="text-danger">Date of birth is required</span>
                )} */}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="service_date">Service date</label>
                <DatePicker
                  // maxDate={maxDate}
                  className="form-control"
                  value={service_date}
                  onChange={(date) => setServiceDate(date)}
                />
                {/* <input
                  type="date"
                  data-date-format="MM/DD/YYYY"
                  data-date=""
                  className="form-control  date-input"
                  placeholder="Service date"
                  min={new Date().toISOString().split("T")[0]}
                  {...register("service_date")}
                /> */}
                {/* {errors.service_date && (
                  <span className="text-danger">Service date is required</span>
                )} */}
              </FormGroup>
            </Row>
            <hr />
            <h4 className="mb-4 text-info">Secondary Account Holder</h4>
            <Row className="row-cols-lg-2">
              <FormGroup>
                <label htmlFor="secondary_name">Name</label>
                <input
                  type="text"
                  name="secondary_name"
                  className="form-control"
                  defaultValue={lead?.secondary_name}
                  {...register("secondary_name")}
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="secondary_phone">Phone</label>
                <input
                  type="tel"
                  name="secondary_phone"
                  className="form-control"
                  onKeyDown={preventAlphabets}
                  defaultValue={lead?.secondary_phone}
                  {...register("secondary_phone")}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="secondary_dob">Date Of Birth</label>
                <DatePicker
                  // maxDate={maxDate}
                  className="form-control"
                  value={secondary_dob}
                  onChange={(date) => setSecondaryDob(date)}
                />
                {/* <input
                  type="date"
                  data-date-format="MM/DD/YYYY"
                  data-date=""
                  className="form-control  date-input"
                  placeholder="Secondary Date of Birth"
                  max={maxDate}
                  {...register("secondary_dob")}
                /> */}
              </FormGroup>
            </Row>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn w-sm btn-primary"
              id="delete-record"
              disabled={isLoading}
            >
              {isLoading ? <Spinner size={"sm"} /> : "Save"}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

EditClientModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default EditClientModal;
