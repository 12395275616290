// import React, { useEffect, useState } from "react";
// import BreadCrumb from "../../Components/Common/BreadCrumb";
// import {
//   Container,
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   Spinner,
// } from "reactstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import Pagination from "../../Components/Common/Pagination";
// import SearchInput from "../../Components/SearchInput";
// import AddServiceModal from "../../Components/services/AddServiceModal";
// import {
//   deleteService,
//   getAllservice,
//   getService,
//   reset,
// } from "../../redux/services/services.slices";
// import DeleteModal from "../../Components/Common/DeleteModal";
// import EditServiceModal from "../../Components/services/EditServiceModal";
// import { toast } from "react-toastify";

// function Services() {
//   const dispatch = useDispatch();

//   const [showAddServiceModal, setshowAddServiceModal] = useState(false);
//   const [showDelete, setshowDelete] = useState(false);
//   const [showEdit, setshowEdit] = useState(false);
//   const [serviceData, setServiceData] = useState(null);
//   const [filteredservices, setFilteredservices] = useState([]);
//   const [keyword, setKeyword] = useState("");

//   const [currentPage, setCurrentPage] = useState(1);
//   const [recordsPerPage] = useState(10);

//   useEffect(() => {
//     dispatch(getAllservice("all"));
//   }, []);

//   const { isLoading, services, message, service, isSuccess } = useSelector(
//     (state) => state.services
//   );

//   useEffect(() => {
//     if (isSuccess) {
//       setshowAddServiceModal(false);
//       setshowDelete(false);
//       setshowEdit(false);
//       setServiceData(null);
//       toast.success(message);
//       dispatch(reset());
//       dispatch(getAllservice("all"));
//     }
//   }, [dispatch, isSuccess]);

//   useEffect(() => {
//     const re = new RegExp(keyword, "i");

//     setFilteredservices(
//       services?.filter((entry) =>
//         Object.values(entry).some(
//           (val) => typeof val == "string" && val.match(re)
//         )
//       )
//     );

//     //get unique data
//   }, [keyword, services]);

//   const columns = [
//     {
//       name: "_id",
//       label: "Service Id",
//     },
//     {
//       name: "name",
//       label: "name",
//     },

//     {
//       name: "amount",
//       label: "amount",
//     },
//     {
//       name: "Status",
//       label: "Status",
//     },
//   ];

//   const handleDelete = () => {
//     dispatch(deleteService(serviceData._id));
//   };

//   const indexOfLastRecord = currentPage * recordsPerPage;
//   const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
//   const currentRecords = filteredservices.slice(
//     indexOfFirstRecord,
//     indexOfLastRecord
//   );
//   const nPages = Math.ceil(filteredservices.length / recordsPerPage);

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <BreadCrumb title="Services" pageTitle="Services" />

//           <Card className="mt-3">
//             <CardHeader>
//               <div className="d-lg-flex justify-content-between w-100">
//                 <div className="d-flex gap-3">
//                   <SearchInput
//                     autoComplete="on"
//                     onChange={(e) => setKeyword(e.target.value)}
//                     value={keyword}
//                   />
//                 </div>
//                 <div className="d-flex mb-3 gap-3">
//                   <Button
//                     color="primary"
//                     onClick={() => setshowAddServiceModal(!showAddServiceModal)}
//                   >
//                     Add New Service
//                   </Button>
//                 </div>
//               </div>
//             </CardHeader>
//             <CardBody>
//               <table className="table table-striped table-responsive-sm table-sm table-hover">
//                 <thead>
//                   <tr className="ml-0">
//                     {columns.map((d, i) => (
//                       <th key={i} className="ms-0 ps-0">
//                         {d.label}
//                       </th>
//                     ))}
//                     <th>Actions</th>
//                   </tr>
//                 </thead>

//                 <tbody>
//                   {isLoading ? (
//                     <tr className="text-center">
//                       <td colSpan={8}>
//                         <Spinner />
//                       </td>
//                     </tr>
//                   ) : currentRecords.length <= 0 ? (
//                     <tr>
//                       <td colSpan={9} className="text-center fw-bolder py-5">
//                         No Data found
//                       </td>
//                     </tr>
//                   ) : (
//                     currentRecords.map((serv, i) => (
//                       <tr key={serv?._id}>
//                         <td>SE0{i + 1}</td>
//                         <td>{serv?.name}</td>
//                         <td>{serv?.amount}</td>
//                         <td>
//                           {serv?.publish ? (
//                             <span className="badge badge-success-light">
//                               active
//                             </span>
//                           ) : (
//                             <span className="badge badge-danger-light">
//                               inactive
//                             </span>
//                           )}
//                         </td>
//                         <td>
//                           <Link
//                             to={`/services/${serv?._id}`}
//                             className="btn h3 mt-0 pt-0"
//                           >
//                             <i className="ri-eye-line"></i>
//                           </Link>
//                           <button
//                             onClick={() => {
//                               setshowEdit(!showEdit);
//                               // setServiceData(serv);
//                               dispatch(getService(serv?._id));
//                             }}
//                             className="btn h2 mt-0 pt-0"
//                           >
//                             {serv?.publish ? (
//                               <i className="ri-toggle-fill text-success"></i>
//                             ) : (
//                               <i className="ri-toggle-line "></i>
//                             )}
//                           </button>
//                           {/* <button
//                             onClick={() => {
//                               setshowDelete(!showDelete);
//                               setServiceData(serv);
//                             }}
//                             className="btn"
//                           >
//                             <i className="ri-delete-bin-6-line"></i>
//                           </button> */}
//                         </td>
//                       </tr>
//                     ))
//                   )}
//                 </tbody>
//                 <tfoot>
//                   <Pagination
//                     nPages={nPages}
//                     currentPage={currentPage}
//                     setCurrentPage={setCurrentPage}
//                   />
//                 </tfoot>
//               </table>
//             </CardBody>
//           </Card>
//         </Container>
//       </div>
//       <AddServiceModal
//         show={showAddServiceModal}
//         onCloseClick={() => setshowAddServiceModal(!showAddServiceModal)}
//       />
//       {!isLoading && service && (
//         <EditServiceModal
//           show={showEdit}
//           onCloseClick={() => setshowEdit(!showEdit)}
//           service={service}
//         />
//       )}

//       <DeleteModal
//         show={showDelete}
//         onCloseClick={() => setshowDelete(!showDelete)}
//         onDeleteClick={handleDelete}
//       />
//     </React.Fragment>
//   );
// }

// export default Services;

import React, { useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";
import AddServiceModal from "../../Components/services/AddServiceModal";
import {
  getAllservice,
  getService,
  reset,
} from "../../redux/services/services.slices";
import EditServiceModal from "../../Components/services/EditServiceModal";
import { toast } from "react-toastify";
import axios from "axios";

const SortableItem = ({ id, service, index, onEdit }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
    border: "1px solid #F2F3F8",
    borderRadius: "4px",
    padding: "1rem",
    margin: "0.5rem 0",
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="list-group-item d-lg-flex justify-content-between align-items-center"
    >
      <div>
        <h5 className="fw-bold">
          SE0{index + 1} - {service.name}
        </h5>
        <p>Amount: ${service.amount}</p>
        <span
          className={`badge ${service.publish ? "bg-success" : "bg-danger"}`}
        >
          {service.publish ? "Active" : "Inactive"}
        </span>
      </div>
      <div className="d-lg-none m-4"></div>
      <div className="">
        <Link
          to={`/services/${service._id}`}
          className="btn btn-sm btn-info me-2"
        >
          <i className="ri-eye-line fw-bold fs-16"></i>
        </Link>

        <Button color={"black"} size="sm" className="me-2">
          <i className="ri-drag-move-line fw-bold fs-16"></i>
        </Button>
      </div>
    </li>
  );
};

function Services() {
  const dispatch = useDispatch();
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [services, setServices] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectedService, setSelectedService] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const {
    isLoading,
    services: fetchedServices,
    message,
    service,
    isSuccess,
  } = useSelector((state) => state.services);

  useEffect(() => {
    dispatch(getAllservice("all"));
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      setShowAddServiceModal(false);
      setShowEdit(false);
      toast.success(message);
      dispatch(reset());
      dispatch(getAllservice("all"));
    }
  }, [dispatch, isSuccess, message]);

  useEffect(() => {
    if (fetchedServices) {
      setServices(fetchedServices);
    }
  }, [fetchedServices]);

  const handleSearch = (e) => {
    setKeyword(e.target.value);
    const filteredServices = fetchedServices.filter((service) =>
      Object.values(service).some(
        (val) =>
          typeof val === "string" &&
          val.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setServices(filteredServices);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setServices((items) => {
        const oldIndex = items.findIndex((item) => item._id === active.id);
        const newIndex = items.findIndex((item) => item._id === over.id);

        const newOrder = arrayMove(items, oldIndex, newIndex);

        axios
          .post(
            process.env.REACT_APP_API_URL + "orders",
            {
              name: "services",
              ids: newOrder.map((pkg) => pkg._id),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            toast.success("Reordered services successfully");
          })
          .catch((err) => {
            toast.error("Could not reorder services");
          });

        return newOrder;
      });
    }
  };

  const handleEdit = (service) => {
    setSelectedService(service);
    setShowEdit(true);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Services" pageTitle="Services" />

          <Card className="mt-3">
            <CardHeader>
              <div className="d-lg-flex justify-content-between w-100">
                <div className="d-flex gap-3">
                  <SearchInput
                    autoComplete="on"
                    onChange={handleSearch}
                    value={keyword}
                  />
                </div>
                <div className="d-flex mb-3 gap-3">
                  <Button
                    color="primary"
                    onClick={() => setShowAddServiceModal(!showAddServiceModal)}
                  >
                    Add New Service
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <div className="text-center">
                  <Spinner />
                </div>
              ) : services.length <= 0 ? (
                <div className="text-center fw-bolder py-5">No Data found</div>
              ) : (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={services.map((service) => service._id)}
                    strategy={verticalListSortingStrategy}
                  >
                    <ul className="list-group">
                      {services.map((service, index) => (
                        <SortableItem
                          key={service._id}
                          id={service._id}
                          service={service}
                          index={index}
                          onEdit={() => handleEdit(service)}
                        />
                      ))}
                    </ul>
                  </SortableContext>
                </DndContext>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <AddServiceModal
        show={showAddServiceModal}
        onCloseClick={() => setShowAddServiceModal(!showAddServiceModal)}
      />
      {/* {!isLoading && selectedService && (
        <EditServiceModal
          show={showEdit}
          onCloseClick={() => setShowEdit(!showEdit)}
          service={selectedService}
        />
      )} */}
    </React.Fragment>
  );
}

export default Services;
