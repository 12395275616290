import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clientService from "./client.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  clients: [],
  clients_graph: [],
  client: null,
  pagination: {},
};

//add new client
export const addClient = createAsyncThunk(
  "clients/add",
  async function (client, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await clientService.addClient(token, client);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all clients
export const getAllClients = createAsyncThunk(
  "clients/all",
  async function (q, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await clientService.getAllClients(token, q);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get all clients
export const getCancelledClients = createAsyncThunk(
  "clients/all/cancelled",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await clientService.getCancelledClients(token);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an client
export const getClient = createAsyncThunk(
  "clients/details",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await clientService.getClient(token, id);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an client
export const getAdminClientGraph = createAsyncThunk(
  "clients/graph",
  async function (year, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await clientService.getAdminClientGraph(token, year);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// DEL a client
export const deleteClient = createAsyncThunk(
  "clients/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await clientService.deleteClient(id, token);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for clients
const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addClient.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.client = action.payload;
      })
      .addCase(addClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getClient.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.client = action.payload;
      })
      .addCase(getClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getAdminClientGraph.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAdminClientGraph.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clients_graph = action.payload;
      })
      .addCase(getAdminClientGraph.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getAllClients.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clients = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(getAllClients.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getCancelledClients.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCancelledClients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clients = action.payload;
      })
      .addCase(getCancelledClients.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(deleteClient.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.client = action.payload;
        state.isSuccess = true;
        state.message = "Deleted Successfully";
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = clientSlice.actions;
export default clientSlice.reducer;
