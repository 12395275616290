import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import MainRoutes from "./routes/Routes";

function App() {
  return (
    <>
      <MainRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
