import React from "react";
import { Badge, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

const LeadClient = ({ client, route }) => (
  <Card className="mb-3 border">
    <CardBody>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4 className="mb-0 fw-bold text-normal text-truncate flex-wrap">
          {client.firstname} {client.lastname}
        </h4>
        <span
          style={{
            color: "#05695B",
            background: "#D2FEF8",
            padding: "5px 10px",
            borderRadius: "15px",
            fontSize: "12px",
          }}
          className="text-normal text-truncate flex-wrap"
        >
          {client.status}
        </span>
      </div>
      <p className="text-muted mb-1">
        <strong className="text-dark"> DOB:</strong>{" "}
        {moment(client.dob).format("MMMM Do, YYYY")}
      </p>
      <p className="text-muted mb-2">
        {client.street}, {client.city}, {client.state} {client.zipCode}
      </p>
      <p className="text-muted mb-2 fs-16">
        <i className="ri-phone-line fs-16"></i> {client.phone}
      </p>
      <div className=" justify-content-between align-items-center border rounded-2 p-4">
        <div>
          <strong>{client.package?.name || "N/A"}</strong>
          <p className="mb-0 fs-24 fw-bold">
            ${client.package?.amount || "N/A"}
          </p>
        </div>
        <Link to={`/${route}/${client._id}`} className="btn btn-primary mt-4">
          View Details
        </Link>
      </div>
    </CardBody>
  </Card>
);

export default LeadClient;
