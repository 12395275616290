import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getPackage, reset } from "../../redux/packages/package.slices";
import { toast } from "react-toastify";
import EditPackageModal from "../../Components/packages/EditPackageModal";

export default function PackageDetails() {
  const dispatch = useDispatch();

  const [showEditPackage, setShowEditPackage] = useState(false);

  const { id } = useParams();
  const { isLoading, packageData, isSuccess, message } = useSelector(
    (state) => state.packages
  );

  useEffect(() => {
    dispatch(getPackage(id));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setShowEditPackage(false);
      toast.success(message);
      dispatch(reset());
      dispatch(getPackage(id));
    }
  }, [dispatch, isSuccess, message]);

  const handleEdit = () => {
    setShowEditPackage(true);
  };

  return (
    <div className="page-content">
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <Container fluid>
          <BreadCrumb title="Package's Detail" pageTitle="Packages" />
          <div className="mb-3 d-flex justify-content-between">
            <Link to={"/packages"}>Go back</Link>
            <Button
              color={packageData?.publish ? "success" : "secondary"}
              size="sm"
              className="me-2"
              onClick={handleEdit}
            >
              <i
                className={`ri-toggle-${
                  packageData?.publish ? "fill" : "line"
                } fw-bold fs-16`}
              ></i>
            </Button>
          </div>

          <Card>
            <CardHeader className="bg-soft-dark">
              <h2 className="my-3 text-primary">
                {packageData?.name}'s <span>details</span>{" "}
              </h2>
            </CardHeader>
            <CardBody>
              <Row>
                {/* <Col lg="4">
                  <h4>ID</h4>
                  <p className="text-muted">{packageData?._id}</p>
                </Col> */}
                <Col lg="4">
                  <h4>Stripe ID</h4>
                  <p className="text-muted">{packageData?.price_id}</p>
                </Col>
                <Col lg="4">
                  <h4>Amount</h4>
                  <p className="text-muted">${packageData?.amount}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      )}

      {!isLoading && packageData && (
        <EditPackageModal
          show={showEditPackage}
          packageData={packageData}
          onCloseClick={() => setShowEditPackage(false)}
        />
      )}
    </div>
  );
}
