import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllLeads, reset } from "../../redux/leads/lead.slices";
import { Button, Container, Spinner } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Pagination, {
  PaginationLeadClient,
} from "../../Components/Common/Pagination";
import SearchInput from "../../Components/SearchInput";
import UploadLeadModal from "../../Components/leads/UploadLead";
import AddLeadModal from "../../Components/leads/AddLeadModal";
import { toast } from "react-toastify";
import LeadClient from "../../Components/leads/LeadClientCard";

function Leads() {
  const dispatch = useDispatch();
  const [showUpload, setShowUpload] = useState(false);
  const [showAddNewLead, setShowAddNewLead] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);

  const { isLoading, leads, message, isSuccess, pagination } = useSelector(
    (state) => state.leads
  );

  useEffect(() => {
    dispatch(
      getAllLeads({
        page: currentPage,
        limit: recordsPerPage,
        search: searchKeyword,
      })
    );
  }, [dispatch, currentPage, recordsPerPage, searchKeyword]);

  useEffect(() => {
    if (isSuccess) {
      setShowAddNewLead(false);
      setShowUpload(false);
      toast.success(message);
      dispatch(reset());
      dispatch(
        getAllLeads({
          page: currentPage,
          limit: recordsPerPage,
          search: searchKeyword,
        })
      );
    }
  }, [dispatch, isSuccess, message]);

  const handleSearch = (e) => {
    setSearchKeyword(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Container fluid className="page-content">
      <BreadCrumb title="Leads" pageTitle="Leads Management" />

      <div className="d-lg-flex justify-content-between align-items-center w-100 my-4">
        <div className="row gap-2 ">
          <Button
            color="primary"
            className="col-5"
            onClick={() => setShowUpload(true)}
          >
            Upload CSV
          </Button>

          <Button
            className="col-6"
            color="success"
            onClick={() => setShowAddNewLead(true)}
          >
            Add New Lead
          </Button>
        </div>

        <div className="d-lg-flex gap-2 mt-4">
          <SearchInput
            placeholder="Search leads..."
            onChange={handleSearch}
            value={searchKeyword}
          />
          <div className="d-lg-none m-4"></div>

          {pagination?.totalLeads && (
            <PaginationLeadClient
              totalItems={pagination?.totalLeads}
              itemsPerPage={recordsPerPage}
              currentPage={currentPage}
              setCurrentPage={handlePageChange}
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      ) : leads?.length === 0 ? (
        <div className="text-center">No leads found.</div>
      ) : (
        <div className="row">
          {leads?.map((lead) => (
            <div key={lead?._id} className="col-md-6 col-lg-4">
              <LeadClient key={lead?._id} client={lead} route={"leads"} />
            </div>
          ))}
        </div>
      )}

      <UploadLeadModal
        show={showUpload}
        onCloseClick={() => setShowUpload(false)}
      />
      <AddLeadModal
        show={showAddNewLead}
        onCloseClick={() => setShowAddNewLead(false)}
      />
    </Container>
  );
}

export default Leads;
