import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients } from "../../redux/clients/client.slices";
import { Container, Spinner } from "reactstrap";
import Pagination, {
  PaginationLeadClient,
} from "../../Components/Common/Pagination";
import LeadClient from "../../Components/leads/LeadClientCard";
import SearchInput from "../../Components/SearchInput";

function Clients() {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);

  const { isLoading, clients, pagination } = useSelector(
    (state) => state.clients
  );

  useEffect(() => {
    dispatch(
      getAllClients({
        page: currentPage,
        limit: recordsPerPage,
        search: searchTerm,
      })
    );
  }, [dispatch, currentPage, recordsPerPage, searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-lg-flex justify-content-between align-items-center mb-4">
          <h1 className="mb-0">Clients</h1>
          <div className="d-lg-flex gap-2 mt-4">
            <SearchInput
              placeholder="Search client..."
              onChange={handleSearch}
              value={searchTerm}
            />
            <div className="d-lg-none m-4"></div>
            {pagination?.totalClients && (
              <PaginationLeadClient
                totalItems={pagination?.totalClients}
                itemsPerPage={recordsPerPage}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
              />
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : clients.length === 0 ? (
          <p className="text-center">No clients found.</p>
        ) : (
          <div className="row">
            {clients.map((client) => (
              <div key={client._id} className="col-md-6 col-lg-4">
                <LeadClient client={client} route="clients" />
              </div>
            ))}
          </div>
        )}
      </Container>
    </div>
  );
}

export default Clients;
