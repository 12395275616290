// import React, { useEffect, useState } from "react";
// import BreadCrumb from "../../Components/Common/BreadCrumb";
// import {
//   Container,
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   Spinner,
// } from "reactstrap";
// import { toast } from "react-toastify";
// import AddPackageModal from "../../Components/packages/AddPackageModal";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import Pagination from "../../Components/Common/Pagination";
// import SearchInput from "../../Components/SearchInput";
// import {
//   deletePackage,
//   getAllPackage,
//   getPackage,
//   reset,
// } from "../../redux/packages/package.slices";
// import DeleteModal from "../../Components/Common/DeleteModal";
// import EditPackageModal from "../../Components/packages/EditPackageModal";

// function Packages() {
//   const dispatch = useDispatch();

//   const [showAddNewPackage, setshowAddNewPackage] = useState(false);
//   const [showEditPackage, setshowEditPackage] = useState(false);
//   const [showDelete, setshowDelete] = useState(false);
//   const [packageData, setPackageData] = useState(null);
//   const [filteredPackages, setFilteredPackages] = useState([]);
//   const [keyword, setKeyword] = useState("");

//   const [currentPage, setCurrentPage] = useState(1);
//   const [recordsPerPage] = useState(10);

//   useEffect(() => {
//     dispatch(getAllPackage("all"));
//   }, []);

//   const {
//     isLoading,
//     packages,
//     packageData: packageData1,
//     message,
//     isSuccess,
//   } = useSelector((state) => state.packages);

//   useEffect(() => {
//     if (isSuccess) {
//       setshowAddNewPackage(false);
//       setshowEditPackage(false);
//       setshowDelete(false);
//       toast.success(message);
//       setPackageData(null);
//       dispatch(reset());
//       dispatch(getAllPackage("all"));
//     }
//   }, [dispatch, isSuccess, message]);

//   useEffect(() => {
//     const re = new RegExp(keyword, "i");

//     setFilteredPackages(
//       packages?.filter((entry) =>
//         Object.values(entry).some(
//           (val) => typeof val == "string" && val.match(re)
//         )
//       )
//     );

//     //get unique data
//   }, [keyword, packages]);

//   const handleDelete = () => {
//     dispatch(deletePackage(packageData._id));
//   };

//   const columns = [
//     {
//       name: "name",
//       label: "name",
//     },

//     {
//       name: "amount",
//       label: "amount",
//     },

//     {
//       name: "price_id",
//       label: "Stripe Price Id",
//     },
//     {
//       name: "Status",
//       label: "Status",
//     },
//   ];

//   const indexOfLastRecord = currentPage * recordsPerPage;
//   const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
//   const currentRecords = filteredPackages.slice(
//     indexOfFirstRecord,
//     indexOfLastRecord
//   );
//   const nPages = Math.ceil(filteredPackages.length / recordsPerPage);

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <BreadCrumb title="Packages" pageTitle="Packages" />

//           <Card className="mt-3">
//             <CardHeader>
//               <div className="d-lg-flex justify-content-between w-100">
//                 <div className="d-flex gap-3">
//                   <SearchInput
//                     autoComplete="on"
//                     onChange={(e) => setKeyword(e.target.value)}
//                     value={keyword}
//                   />
//                 </div>
//                 <div className="d-flex mb-3 gap-3">
//                   <Button
//                     color="primary"
//                     onClick={() => setshowAddNewPackage(!showAddNewPackage)}
//                   >
//                     Add New Package
//                   </Button>
//                 </div>
//               </div>
//             </CardHeader>
//             <CardBody>
//               <table className="table table-striped table-responsive-sm table-sm table-hover">
//                 <thead>
//                   <tr>
//                     {columns.map((d, i) => (
//                       <th className="text-left ps-0" key={i}>
//                         {d.label}
//                       </th>
//                     ))}
//                     <th>Actions</th>
//                   </tr>
//                 </thead>

//                 <tbody>
//                   {isLoading ? (
//                     <tr className="text-center">
//                       <td colSpan={8}>
//                         <Spinner />
//                       </td>
//                     </tr>
//                   ) : currentRecords.length <= 0 ? (
//                     <tr>
//                       <td colSpan={9} className="text-center fw-bolder py-5">
//                         No Data found
//                       </td>
//                     </tr>
//                   ) : (
//                     currentRecords.map((p, i) => (
//                       <tr key={i} className="my-auto">
//                         {/* <td>{p._id}</td> */}
//                         <tdp>{p.name}</tdp>
//                         <td>{p.amount.toFixed(2)}</td>
//                         <td>{p.price_id}</td>
//                         <td>
//                           {p?.publish ? (
//                             <span className="badge badge-success-light">
//                               active
//                             </span>
//                           ) : (
//                             <span className="badge badge-danger-light">
//                               inactive
//                             </span>
//                           )}
//                         </td>

//                         <td>
//                           <Link
//                             to={`/packages/${p._id}`}
//                             className="btn h3 mt-0 pt-0"
//                           >
//                             <i className="ri-eye-line"></i>
//                           </Link>
//                           <button
//                             onClick={() => {
//                               setshowEditPackage(true);
//                               // setPackageData(p);
//                               dispatch(getPackage(p?._id));
//                             }}
//                             className="btn h2 mt-0 pt-0"
//                           >
//                             {p?.publish ? (
//                               <i className="ri-toggle-fill text-success"></i>
//                             ) : (
//                               <i className="ri-toggle-line"></i>
//                             )}
//                           </button>
//                           {/* <button
//                             onClick={() => {
//                               setshowDelete(!showDelete);
//                               setPackageData(p);
//                             }}
//                             className="btn"
//                           >
//                             <i className="ri-delete-bin-6-line"></i>
//                           </button> */}
//                         </td>
//                       </tr>
//                     ))
//                   )}
//                 </tbody>
//                 <tfoot>
//                   <Pagination
//                     nPages={nPages}
//                     currentPage={currentPage}
//                     setCurrentPage={setCurrentPage}
//                   />
//                 </tfoot>
//               </table>
//             </CardBody>
//           </Card>
//         </Container>
//       </div>
//       <AddPackageModal
//         show={showAddNewPackage}
//         onCloseClick={() => setshowAddNewPackage(!showAddNewPackage)}
//       />
//       {!isLoading && packageData1 && (
//         <EditPackageModal
//           show={showEditPackage}
//           packageData={packageData1}
//           onCloseClick={() => setshowEditPackage(!showEditPackage)}
//         />
//       )}

//       <DeleteModal
//         show={showDelete}
//         onCloseClick={() => setshowDelete(!showDelete)}
//         onDeleteClick={handleDelete}
//       />
//     </React.Fragment>
//   );
// }

// export default Packages;
import React, { useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllPackage, reset } from "../../redux/packages/package.slices";
import SearchInput from "../../Components/SearchInput";
import axios from "axios";
import { toast } from "react-toastify";
import AddPackageModal from "../../Components/packages/AddPackageModal";
import EditPackageModal from "../../Components/packages/EditPackageModal";

const SortableItem = ({ id, item, onEdit }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
    border: "1px solid #F2F3F8",
    borderRadius: "4px",
    padding: "1rem",
    margin: "0.5rem 0",
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="list-group-item d-flex flex-wrap justify-content-between align-items-center py-2"
    >
      <div>
        <h5 className="fw-bold">{item.name}</h5>
        <p>Amount: ${item.amount.toFixed(2)}</p>
        <p>Stripe Price ID: {item.price_id}</p>
        <span className={`badge ${item.publish ? "bg-success" : "bg-danger"}`}>
          {item.publish ? "Active" : "Inactive"}
        </span>
      </div>
      <div className="d-lg-none m-4"></div>

      <div>
        <Link to={`/packages/${item._id}`} className="btn btn-sm btn-info me-2">
          <i className="ri-eye-line fw-bold fs-16"></i>
        </Link>

        <Button color="black" size="sm" className="me-2">
          <i className="ri-drag-move-line fw-bold fs-16"></i>
        </Button>
      </div>
    </li>
  );
};

const ReorderablePackageList = () => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [showAddNewPackage, setShowAddNewPackage] = useState(false);
  const [showEditPackage, setShowEditPackage] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const {
    isLoading,
    packages: fetchedPackages,
    isSuccess,
    message,
  } = useSelector((state) => state.packages);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    dispatch(getAllPackage("all"));
  }, [dispatch]);

  useEffect(() => {
    if (fetchedPackages) {
      setPackages(fetchedPackages);
    }
  }, [fetchedPackages]);

  useEffect(() => {
    if (isSuccess) {
      setShowAddNewPackage(false);
      setShowEditPackage(false);
      setShowDelete(false);
      toast.success(message);
      setSelectedPackage(null);
      dispatch(reset());
      dispatch(getAllPackage("all"));
    }
  }, [dispatch, isSuccess, message]);

  const handleSearch = (e) => {
    setKeyword(e.target.value);
    const filteredPackages = fetchedPackages.filter((pkg) =>
      Object.values(pkg).some(
        (val) =>
          typeof val === "string" &&
          val.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setPackages(filteredPackages);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setPackages((items) => {
        const oldIndex = items.findIndex((item) => item._id === active.id);
        const newIndex = items.findIndex((item) => item._id === over.id);

        const newOrder = arrayMove(items, oldIndex, newIndex);

        axios
          .post(
            process.env.REACT_APP_API_URL + "orders",
            {
              name: "packages",
              ids: newOrder.map((pkg) => pkg._id),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            toast.success("Reordered packages successfully");
          })
          .catch((err) => {
            toast.error("Could not reorder packages");
          });

        return newOrder;
      });
    }
  };

  const handleEdit = (pkg) => {
    setSelectedPackage(pkg);
    setShowEditPackage(true);
  };

  const handleDelete = (pkg) => {
    setSelectedPackage(pkg);
    setShowDelete(true);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Card className="mt-3">
          <CardHeader>
            <div className="d-lg-flex justify-content-between w-100">
              <div className="d-flex gap-3 w-sm-100">
                <SearchInput
                  autoComplete="on"
                  onChange={handleSearch}
                  value={keyword}
                />
              </div>
              <div className="my-4 d-md-hidden"></div>
              <div className="d-flex mb-3 gap-3">
                <Button
                  color="primary"
                  onClick={() => setShowAddNewPackage(true)}
                >
                  Add New Package
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody className="bg-tertiary">
            {isLoading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : packages.length <= 0 ? (
              <div className="text-center fw-bolder py-5">No Data found</div>
            ) : (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={packages.map((pkg) => pkg._id)}
                  strategy={verticalListSortingStrategy}
                >
                  <ul className="list-group">
                    {packages.map((pkg) => (
                      <SortableItem
                        key={pkg._id}
                        id={pkg._id}
                        item={pkg}
                        onDelete={() => handleDelete(pkg)}
                        onEdit={() => handleEdit(pkg)}
                      />
                    ))}
                  </ul>
                </SortableContext>
              </DndContext>
            )}
          </CardBody>
        </Card>
      </Container>

      <AddPackageModal
        show={showAddNewPackage}
        onCloseClick={() => setShowAddNewPackage(false)}
      />

      {!isLoading && selectedPackage && (
        <EditPackageModal
          show={showEditPackage}
          packageData={selectedPackage}
          onCloseClick={() => setShowEditPackage(false)}
        />
      )}

      {/* <DeleteModal
        show={showDelete}
        onCloseClick={() => setShowDelete(false)}
        onDeleteClick={confirmDelete}
      /> */}
    </div>
  );
};

export default ReorderablePackageList;
