import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import addendumService from "./addendum.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  addendum: null,
  addendums: [],
};

//create an addendum
export const createAddendum = createAsyncThunk(
  "addendums/create",
  async function (addendum, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await addendumService.createAddendum(addendum, token);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

const addendumSlice = createSlice({
  name: "addendum",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAddendum.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAddendum.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.addendum = action.payload;
      })
      .addCase(createAddendum.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export default addendumSlice.reducer;

export const { reset } = addendumSlice.actions;
