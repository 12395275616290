import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import settingsService from "./setting.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  twilio: null,
  sendgrid: null,
  stripe: null,
};

//GET twilio settingss
export const getTwilioSettings = createAsyncThunk(
  "settings/twilio",
  async function (setting, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await settingsService.getTwilioSettings(token, setting);

      return res;
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//GET sendGrid settingss
export const getSendGridSettings = createAsyncThunk(
  "settings/sendgrid",
  async function (setting, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await settingsService.getSendGridSettings(token, setting);

      return res;
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//GET stripe settingss
export const getStripeSettings = createAsyncThunk(
  "settings/stripe",
  async function (setting, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await settingsService.getStripeSettings(token, setting);

      return res;
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//UPDATE a settingss
export const updatesettings = createAsyncThunk(
  "settings/update",
  async function (settingsData, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;

      const res = await settingsService.updateSettings(token, settingsData);

      return res;
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// create slice
const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder

      .addCase(updatesettings.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(updatesettings.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updatesettings.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(getTwilioSettings.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getTwilioSettings.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.twilio = actions.payload;
      })
      .addCase(getTwilioSettings.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(getSendGridSettings.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getSendGridSettings.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.sendgrid = actions.payload;
      })
      .addCase(getSendGridSettings.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(getStripeSettings.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getStripeSettings.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.stripe = actions.payload;
      })
      .addCase(getStripeSettings.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      });
  },
});

export const { reset } = settingsSlice.actions;
export default settingsSlice.reducer;
