import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

export function LineChart({ chartData, data: dataProps, startDate, endDate }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          // Custom tooltip to show the month and count
          label: function (context) {
            const label = context.dataset.label || "";
            const month = context.label; // The month from labels
            const value = context.raw; // The count value

            return `Month: ${month}, Value: ${value}`;
          },
        },
      },
    },
  };

  const data = {
    labels: dataProps?.map((i) => i?._id?.month),
    datasets: [
      {
        label: `${chartData} for : ${moment(startDate).format(
          "MM/DD/YYYY"
        )} - ${moment(endDate).format("MM/DD/YYYY")}`,
        data: dataProps?.map((i) => i?.count),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Line options={options} data={data} />;
}
