import React from "react";

const SearchInput = (props) => {
  const { value, onChange } = props;
  return (
    <div className="position-relative auth-pass-inputgroup w-sm-100">
      <input
        name="search"
        className="form-control pe-5"
        placeholder="search..."
        value={value}
        onChange={onChange}
        {...props}
      />
      <button
        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
        type="button"
      >
        <i className="ri-search-line align-middle"></i>
      </button>
    </div>
  );
};

export default SearchInput;
