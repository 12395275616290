import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./user.service";

const initialState = {
  users: [],
  user: null,
  isError: false,
  isLoading: false,
  isDetailsLoading: true,
  isSuccess: false,
  isUpdate: false,
  message: "",
};

//getAllUsers a user
export const getAllUsers = createAsyncThunk(
  "users/all",
  async function (_, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await userService.getAllUsers(token);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//activate or deactivate a user
export const updateUser = createAsyncThunk(
  "users/update",
  async function (user, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await userService.updateUser(token, user);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//Update a user
export const updateUserByAdmin = createAsyncThunk(
  "users/updateByAdmin",
  async function (user, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await userService.updateUserInfo(token, user);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get a user
export const userById = createAsyncThunk(
  "users/userById",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await userService.userDetailsById(token, id);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(updateUserByAdmin.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateUserByAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdate = true;
      })
      .addCase(updateUserByAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(userById.pending, (state, action) => {
        state.isDetailsLoading = true;
      })
      .addCase(userById.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.user = action.payload;
      })
      .addCase(userById.rejected, (state, action) => {
        state.isDetailsLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});
export const { reset } = userSlice.actions;
export default userSlice.reducer;
