import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import serviceService from "./services.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  service: null,
  services: [],
};

//GET all services
export const addService = createAsyncThunk(
  "services/add",
  async function (serviceData, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await serviceService.addService(token, serviceData);

      return res;
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//GET a services
export const getService = createAsyncThunk(
  "services/single",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await serviceService.getService(token, id);

      return res;
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//UPDATE a services
export const updateService = createAsyncThunk(
  "services/update",
  async function (serviceData, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;

      const res = await serviceService.updateService(
        token,
        serviceData?.id,
        serviceData?.data
      );

      return res;
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//DELETE a services
export const deleteService = createAsyncThunk(
  "services/delete",
  async function (id, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;

      const res = await serviceService.deleteService(token, id);

      return res;
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//GET all services
export const getAllservice = createAsyncThunk(
  "services/all",
  async function (status, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const res = await serviceService.getAllService(token, status);

      return res;
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// create slice
const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addService.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(addService.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.services.push(actions.payload);
        state.isSuccess = true;
        state.message = "One-Time fee Added Successfully";
      })
      .addCase(addService.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(updateService.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(updateService.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "One-Time fee Updated Successfully";
      })
      .addCase(updateService.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(getAllservice.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getAllservice.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.services = actions.payload;
      })
      .addCase(getAllservice.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(deleteService.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(deleteService.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.services.filter((item) => item._id !== actions.payload._id);
        state.isSuccess = true;
        state.message = "One-Time fee Deleted Successfully";
      })
      .addCase(deleteService.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      })
      .addCase(getService.pending, (state, actions) => {
        state.isLoading = true;
      })
      .addCase(getService.fulfilled, (state, actions) => {
        state.isLoading = false;
        state.service = actions.payload;
      })
      .addCase(getService.rejected, (state, actions) => {
        state.isLoading = false;
        state.isError = true;
        state.message = actions.payload.message;
      });
  },
});

export const { reset } = serviceSlice.actions;
export default serviceSlice.reducer;
