import { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Widget from "./Widget";
import { LineChart } from "../../Components/Common/charts/LineChart";
import { getAllLeads } from "../../redux/leads/lead.slices";
import {
  getAllCapturedTransactions,
  getAllProjectedTransactions,
  getCustomers,
} from "../../redux/stripes/stripes.slices";
import { getAllPackage } from "../../redux/packages/package.slices";
import { getAllClients } from "../../redux/clients/client.slices";
import axios from "axios";
import moment from "moment";
import { convertDateInput } from "../../utils/dateUtils";

const Home = () => {
  const dispatch = useDispatch();

  const [chartData, setchartData] = useState("Total Clients");
  const [startDate, setStartDate] = useState(
    moment("2023-01-01").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [statistics, setStatistics] = useState({});

  const { user } = useSelector((state) => state.auth);

  const { customers } = useSelector((state) => state.stripes);

  useEffect(() => {
    dispatch(getAllLeads());
    dispatch(getCustomers());
    dispatch(getAllClients());

    dispatch(getAllCapturedTransactions());
    dispatch(getAllProjectedTransactions());
    dispatch(getAllPackage());

    convertDateInput();
  }, []);

  useEffect(() => {
    const start = moment(startDate).startOf("day").toDate();
    const end = moment(endDate).endOf("day").toDate();
    const fetchDashboard = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}dashboards?startDate=${start}&endDate=${end}`,
        {
          headers: {
            Authorization: "Bearer " + user?.token,
          },
        }
      );
      setStatistics(data?.data);
    };

    fetchDashboard();
  }, [endDate, startDate, user?.token]);

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Statistic charts" pageTitle="Dashboards" />
          <div className="d-flex my-4">
            <div className="form-group d-lg-flex align-items-center">
              <label htmlFor="start" className="text-nowrap">
                Start date :{" "}
              </label>
              <input
                type="date"
                data-date=""
                data-date-format="MM/DD/YYYY"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="form-control date-input"
              />
            </div>
            <div className="form-group ms-3 d-lg-flex align-items-center">
              <label htmlFor="start" className="text-nowrap">
                End date :{"  "}
              </label>

              <input
                type="date"
                data-date=""
                data-date-format="MM/DD/YYYY"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="form-control date-input"
              />
            </div>
          </div>
          <div className="my-3">
            <Widget
              totalClients={statistics?.summary?.clients}
              averageRevenue={statistics?.summary?.averageMRR}
              totalLeads={statistics?.summary?.leads}
              totalCustomers={customers?.length}
              onClick={setchartData}
            />
          </div>
          <Row>
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <div>
                  <h6 className="">
                    {chartData}{" "}
                    <span className="badge badge-success-light"> From</span>:{" "}
                    {moment(startDate).format("MM/DD/YYYY")}
                    <span className="badge badge-success-light"> To</span>:{" "}
                    {moment(endDate).format("MM/DD/YYYY")}
                  </h6>
                </div>
              </CardHeader>
              <CardBody>
                <LineChart
                  chartData={chartData}
                  startDate={startDate}
                  endDate={endDate}
                  data={
                    chartData === "Total Clients"
                      ? statistics?.clients
                      : statistics?.leads
                  }
                />
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Home;
