import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormGroup, Modal, ModalBody, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createAddendum, reset } from "../../redux/addendum/addendum.slice";
import { useEffect } from "react";

const AddAddendumModal = ({ show, onCloseClick, lead, client }) => {
  const dispatch = useDispatch();

  const { isLoading, isError, isSuccess } = useSelector(
    (state) => state.addendum
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success("Addendum created successfully");
      dispatch(reset());
      onCloseClick();
    }
  }, [isSuccess]);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: lead?.firstname,
      lastname: lead?.lastname,
      email: lead?.email,
      term: "initial",
      // Removed default value for renewal_months
    },
  });

  const onSubmit = async (data) => {
    dispatch(
      createAddendum({
        ...data,
        lead: lead._id,
        client: client._id,
        subscription: client.subscription,
      })
    );
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="px-5">
        <h3 className="fw-bold">Add Addendum</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <label htmlFor="firstname">First Name</label>
            <input
              type="text"
              name="firstname"
              className="form-control"
              {...register("firstname", { required: true })}
            />
            {errors.firstname && (
              <span className="text-danger">First name is required</span>
            )}
          </FormGroup>
          <FormGroup>
            <label htmlFor="lastname">Last Name</label>
            <input
              type="text"
              name="lastname"
              className="form-control"
              {...register("lastname", { required: true })}
            />
            {errors.lastname && (
              <span className="text-danger">Last name is required</span>
            )}
          </FormGroup>
          <FormGroup>
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              className="form-control"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="text-danger">Email is required</span>
            )}
          </FormGroup>

          <FormGroup>
            <label htmlFor="renewal_amount">Renewal Amount</label>
            <input
              type="number"
              step="0.01"
              name="renewal_amount"
              className="form-control"
              {...register("renewal_amount", {
                required: true,
                valueAsNumber: true,
                validate: (value) =>
                  !isNaN(value) || "Please enter a valid number",
              })}
            />
            {errors.renewal_amount && (
              <span className="text-danger">
                {errors.renewal_amount.type === "validate"
                  ? errors.renewal_amount.message
                  : "Renewal amount is required"}
              </span>
            )}
          </FormGroup>

          <div className="my-4">
            <p className="font-semibold">Service Term:</p>
            <div className="my-2">
              <div>
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="radio"
                    name="term"
                    value="initial"
                    {...register("term", { required: true })}
                    className="h-4 w-4 border-gray-300 rounded"
                  />
                  <span className="text-muted">Client initial term</span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="radio"
                    name="term"
                    value="renewal"
                    {...register("term", { required: true })}
                    className="h-4 w-4 border-gray-300 rounded"
                  />
                  <span className="text-muted">
                    Client agrees to a renewal term of months
                  </span>
                </div>
              </div>
            </div>
            {errors.term && (
              <span className="text-danger">Service term is required</span>
            )}
          </div>

          {watch("term") !== "initial" && (
            <FormGroup>
              <label htmlFor="renewal_months">Renewal Term in Months</label>
              <input
                type="number"
                name="renewal_months"
                className="form-control"
                step="1"
                {...register("renewal_months", {
                  required: true,
                  valueAsNumber: true,
                  validate: (value) =>
                    Number.isInteger(value) || "Please enter a whole number",
                })}
              />
              {errors.renewal_months && (
                <span className="text-danger">
                  {errors.renewal_months.type === "validate"
                    ? errors.renewal_months.message
                    : "Renewal months is required"}
                </span>
              )}
            </FormGroup>
          )}

          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn w-sm btn-primary"
              id="delete-record"
            >
              {isLoading ? <Spinner size={"sm"} /> : "Save Addendum"}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

AddAddendumModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default AddAddendumModal;
