import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./auth.service";

const user = JSON.parse(localStorage.getItem("savvycams"));

const initialState = {
  user: user ? user : null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  isPasswordResetLoading: false,
  isPasswordResetSuccess: false,
  message: "",
};

//login a user

export const login = createAsyncThunk(
  "auth/login",
  async function (user, thunkAPI) {
    try {
      return await authService.login(user);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        "Invalid credentials";
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//set new password for a user

export const setNewPassword = createAsyncThunk(
  "auth/newPassword",
  async function (cred, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await authService.setNewPassword(cred, token);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
//Update a user

export const updateProfile = createAsyncThunk(
  "auth/profileUpdate",
  async function (user, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await authService.updateProfile(user, token);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//reset password for a user

export const sendResetPasswordLink = createAsyncThunk(
  "auth/resetLink",
  async function (cred, thunkAPI) {
    try {
      return await authService.sendResetPasswordLink(cred);
    } catch (error) {
      const message =
        error.data.message ||
        error?.response?.data?.message ||
        error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
//reset password for a user

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async function (cred, thunkAPI) {
    try {
      return await authService.resetPassword(cred);
    } catch (error) {
      const message =
        error.data.message ||
        error?.response?.data?.message ||
        error?.response?.data?.error;
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//logout a user
export const logout = createAsyncThunk(
  "auth/logout",
  async function (thunkAPI) {
    return authService.logout();
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isPasswordResetLoading = false;
      state.isPasswordResetSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload; // will change this later;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.user = null;
      })
      .addCase(setNewPassword.pending, (state, action) => {
        state.isPasswordResetLoading = true;
      })
      .addCase(setNewPassword.fulfilled, (state, action) => {
        state.isPasswordResetLoading = false;
        state.user = null;
        state.isPasswordResetSuccess = true;
      })
      .addCase(setNewPassword.rejected, (state, action) => {
        state.isPasswordResetSuccess = false;
        state.isPasswordResetLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(updateProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(sendResetPasswordLink.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(sendResetPasswordLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(sendResetPasswordLink.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        // state.message = action.payload.message;
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        // state.message = action.payload.message;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.user = null;
      });
  },
});
export const { reset } = authSlice.actions;
export default authSlice.reducer;
