import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getAllPackage } from "../../redux/packages/package.slices";
import { getAllManagers } from "../../redux/managers/managers.slices";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { addLead } from "../../redux/leads/lead.slices";
import { getAllservice } from "../../redux/services/services.slices";
import AddPackageModal from "../packages/AddPackageModal";
import moment from "moment";
import { convertDateInput } from "../../utils/dateUtils";
import { daysOption } from "../../data/daysOptions";

const animatedComponents = makeAnimated();

const AddLeadModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();

  const date = new Date();
  const maxDate = moment(date.setFullYear(date.getFullYear() - 18)).format(
    "MM/DD/YYYY"
  );

  // const minDate = moment(date.setMonth(date.getMonth() + 1)).format(
  //   "MM/DD/YYYY"
  // );

  const [recurringDate, setRecurringDate] = useState(null);
  const [packageSelect, setPackageSelect] = useState(null);
  const [fees, setFees] = useState([]);
  const [feeAmount, setFeeAmount] = useState(0);
  const [feeName, setFeeName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAddNewPackage, setshowAddNewPackage] = useState(false);

  useEffect(() => {
    dispatch(getAllPackage("published"));
    dispatch(getAllManagers());
    dispatch(getAllservice("published"));
  }, []);

  const { packages } = useSelector((state) => state.packages);
  const { managers } = useSelector((state) => state.managers);
  const { services } = useSelector((state) => state.services);
  const { user } = useSelector((state) => state.auth);

  const {
    isLoading,
    isError,
    message,
    isSuccess: isSuccessLead,
  } = useSelector((state) => state.leads);

  const { packageData, isSuccess } = useSelector((state) => state.packages);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    convertDateInput();
  }, [watch("dob"), watch("service_date"), watch("secondary_dob")]);

  useEffect(() => {
    if (watch("one_time_fee") === "Others") {
      setShowModal(!showModal);
    } else {
      setShowModal(false);
    }
  }, [watch("one_time_fee")]);

  useEffect(() => {
    if (watch("package") === "Others") {
      setshowAddNewPackage(true);
    } else {
      setshowAddNewPackage(false);
    }
  }, [watch("package")]);

  useEffect(() => {
    if (isSuccess) {
      setPackageSelect(packageData._id);
      setshowAddNewPackage(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessLead) {
      reset();
    }
  }, [isSuccessLead]);

  const handleSave = (data) => {
    if (recurringDate === null) {
      return toast.warning("Recurring date is required");
    }

    dispatch(
      addLead({
        ...data,
        billing_date: recurringDate?.value,
        onetime_fees:
          fees.length > 0
            ? fees
            : [
                {
                  feeName: watch("one_time_fee").split("-")[0] || "",
                  feeAmount: watch("one_time_fee").split("-")[1] || 0,
                },
              ],
      })
    );
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
    const keyCode = e.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 190 || // allow period (decimal point)
        excludedKeys.includes(keyCode)
      )
    ) {
      e.preventDefault();
    }

    const inputValue = e.target.value;
    const hasDecimal = inputValue.includes(".");
    if (keyCode === 190 && hasDecimal) {
      // prevent entering more than one decimal point
      e.preventDefault();
    }
  };

  const preventAlphabets = (event) => {
    // Allow only numbers, backspace, delete, plus and minus keys
    if (
      !(
        (
          event.keyCode === 8 || // Backspace
          event.keyCode === 46 || // Delete
          event.keyCode === 43 || // Plus
          event.keyCode === 45 || // Minus
          event.shiftKey ||
          (event.keyCode >= 48 && event.keyCode <= 57) || // Numbers 0-9
          (event.keyCode >= 96 && event.keyCode <= 105)
        ) // Numpad numbers
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size="lg">
      <ModalBody className="py-3 px-5">
        <form onSubmit={handleSubmit(handleSave)}>
          <div className="mt-2">
            <h3 className="mb-4 text-muted mx-auto w-100 text-center">
              Add new lead
            </h3>
            <h4 className="mb-4 text-info">Primary Account Holder</h4>
            <Row className="row-cols-lg-2">
              <FormGroup>
                <label htmlFor="firstname">First Name</label>
                <input
                  type="text"
                  name="firstname"
                  className="form-control"
                  {...register("firstname", { required: true })}
                />
                {errors.firstname && (
                  <span className="text-danger">First name is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="lastname">Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  className="form-control"
                  {...register("lastname", { required: true })}
                />
                {errors.lastname && (
                  <span className="text-danger">Last name is required</span>
                )}
              </FormGroup>

              <FormGroup>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className="text-danger">Email is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  onKeyDown={preventAlphabets}
                  {...register("phone", { required: true })}
                />
                {errors.phone && (
                  <span className="text-danger">Phone is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="street">Street</label>
                <input
                  type="text"
                  name="street"
                  className="form-control"
                  {...register("street", { required: true })}
                />
                {errors.street && (
                  <span className="text-danger">Street is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  {...register("city", { required: true })}
                />
                {errors.city && (
                  <span className="text-danger">City is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  name="state"
                  className="form-control"
                  {...register("state", { required: true })}
                />
                {errors.state && (
                  <span className="text-danger">State is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="zipCode">Zip code</label>
                <input
                  type="text"
                  name="zipCode"
                  className="form-control"
                  {...register("zipCode", { required: true })}
                />
                {errors.zipCode && (
                  <span className="text-danger">ZipCode is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="zipCode">Payment method</label>
                <div className="d-flex align-items-center gap-3">
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="paymentMethod"
                      className="form-check me-3"
                      value={"card"}
                      {...register("paymentMethod", { required: true })}
                    />
                    Credit card
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="paymentMethod"
                      className="form-check me-3"
                      value={"ach"}
                      {...register("paymentMethod", { required: true })}
                    />
                    ACH
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="paymentMethod"
                      className="form-check me-3"
                      value={"both"}
                      {...register("paymentMethod", { required: true })}
                    />
                    Both
                  </div>
                </div>

                {errors.paymentMethod && (
                  <span className="text-danger">
                    Payment method is required
                  </span>
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="contract_length">Contract length</label>
                <select
                  type="number"
                  name="contract_length"
                  className="form-control"
                  min={1}
                  max={60}
                  onKeyDown={preventMinus}
                  {...register("contract_length", { required: true })}
                >
                  <option value="">Select number of months</option>
                  {/* {Array.from({ length: 60 }, (_, index) => (
                    <option key={index} value={index + 1}>
                      {index + 1}
                    </option>
                  ))} */}
                  <option value={48}>48</option>
                  <option value={60}>60</option>
                </select>
                {errors.contract_length && (
                  <span className="text-danger">
                    Contract length is required
                  </span>
                )}
                {watch("contract_length") > 60 && (
                  <span className="text-danger">
                    Contract length is greater than 60
                  </span>
                )}
              </FormGroup>

              <FormGroup className="col-md-6">
                <label htmlFor="recurring_date">Recurring Billing Date</label>
                <Select
                  components={animatedComponents}
                  isMulti={false}
                  name="users"
                  className="basic-multi-select"
                  placeholder="select a day for recurring payment..."
                  classNamePrefix="select"
                  closeMenuOnSelect={true}
                  options={daysOption}
                  value={recurringDate}
                  onChange={(e) => setRecurringDate(e)}
                />
                {/* {recurringDate === null && (
                  <span className="text-danger">
                    Recurring Billing Date is required
                  </span>
                )} */}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="home_ownership">Home ownership</label>
                <select
                  type="text"
                  name="home_owner"
                  className="form-select"
                  {...register("home_ownership")}
                >
                  <option value=""></option>
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </select>
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="credit_grade">Credit grade</label>
                <select
                  type="text"
                  name="credit_grade"
                  className="form-select"
                  {...register("credit_grade")}
                >
                  <option value=""></option>
                  <option value={"A"}>A</option>
                  <option value={"B"}>B</option>
                  <option value={"C"}>C</option>
                  <option value={"D"}>D</option>
                  <option value={"F"}>F</option>
                  <option value={"Z"}>Z</option>
                </select>
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="package">Package</label>
                <select
                  type="text"
                  name="package"
                  className="form-select"
                  // value={packageSelect}
                  // onChange={(e) => setPackageSelect(e.target.value)}
                  {...register("package", { required: true })}
                >
                  <option value=""></option>
                  {packages.map((p) => (
                    <option value={p._id}>
                      {p.name}-(${p.amount})
                    </option>
                  ))}
                  {user && user?.isAdmin && (
                    <>
                      <hr className="border bg-white text-white" />

                      <option value={"Others"}>Special package?</option>
                    </>
                  )}
                </select>
                {errors.package && (
                  <span className="text-danger">Package is required</span>
                )}
              </FormGroup>
              {watch("package") === "Others" && (
                <AddPackageModal
                  show={showAddNewPackage}
                  onCloseClick={() => setshowAddNewPackage(!showAddNewPackage)}
                />
              )}

              <FormGroup className="col-md-6">
                <label htmlFor="manager">Manager</label>
                <select
                  type="text"
                  name="manager"
                  className="form-select"
                  {...register("manager", { required: true })}
                >
                  <option value=""></option>
                  {managers.map((m) => (
                    <option value={m._id}>
                      {m.firstname} {m.lastname}
                    </option>
                  ))}
                </select>
                {errors.manager && (
                  <span className="text-danger">Manager is required</span>
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="installer">Installer</label>
                <select
                  type="text"
                  name="installer"
                  className="form-select"
                  {...register("installer", { required: true })}
                >
                  <option value=""></option>
                  {managers.map((m) => (
                    <option value={m._id}>
                      {m.firstname} {m.lastname}
                    </option>
                  ))}
                </select>
                {errors.installer && (
                  <span className="text-danger">Installer is required</span>
                )}
                <br />
                <a
                  target={"_blank"}
                  href={
                    managers?.find((m) => m._id === watch("installer"))
                      ?.calendly_link || ""
                  }
                >
                  {managers?.find((m) => m._id === watch("installer"))
                    ?.calendly_link || ""}
                </a>
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="one_time_fee">One time fee</label>
                <select
                  type="text"
                  name="one_time_fee"
                  className="form-select"
                  {...register("one_time_fee")}
                >
                  <option value=""></option>
                  {services.map((serv) => (
                    <option value={`${serv?.name}-${serv?.amount}`}>
                      {`${serv?.name} - $${serv?.amount}`}
                    </option>
                  ))}

                  {user && user?.isAdmin && (
                    <>
                      <hr className="border bg-white text-white" />
                      <option value={"Others"}>
                        Customize it?{" "}
                        {fees.length > 0 &&
                          "- $" +
                            fees.reduce(
                              (acc, curr) => (acc += +curr?.feeAmount),
                              0
                            )}
                      </option>
                    </>
                  )}
                </select>
                {errors.one_time_fee && (
                  <span className="text-danger">One time fee is required</span>
                )}
              </FormGroup>
              {watch("one_time_fee") === "Others" && (
                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                  centered={true}
                  size="md"
                >
                  <ModalBody className="py-3 px-5">
                    <ModalHeader>
                      <h3 className="text-muted">Add Custom fees</h3>
                    </ModalHeader>
                    {fees.length !== 0 && (
                      <div className="my-4">
                        <div className="row fw-bolder">
                          <Col>Name</Col>
                          <Col>Amount</Col>
                          <Col>Action</Col>
                        </div>
                        <hr />
                        {fees.map((f, i) => (
                          <div key={i} className="row">
                            <Col>{f.feeName}</Col>
                            <Col>{f.feeAmount}</Col>
                            <Col>
                              <button
                                onClick={() => {
                                  setFees([
                                    ...fees.filter(
                                      (fe) => fe.feeName !== f.feeName
                                    ),
                                  ]);
                                }}
                                className="text-danger"
                              >
                                X
                              </button>
                            </Col>
                          </div>
                        ))}
                      </div>
                    )}

                    <>
                      <hr />
                      <FormGroup>
                        <label htmlFor="fee_name">Fee name</label>
                        <input
                          type="text"
                          name="fee_name"
                          className="form-control"
                          value={feeName}
                          onChange={(e) => setFeeName(e.target.value)}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="fee_amount">Fee Amount</label>
                        <input
                          type="text"
                          name="fee_amount"
                          className="form-control"
                          onKeyDown={preventMinus}
                          value={feeAmount}
                          onChange={(e) => {
                            if (e.target.value >= 0) {
                              setFeeAmount(e.target.value);
                            } else {
                              setFeeAmount(0);
                            }
                          }}
                          min="0"
                        />
                      </FormGroup>
                      <button
                        onClick={() => {
                          if (feeAmount >= 0 && feeName.trim() !== "") {
                            setFees([...fees, { feeName, feeAmount }]);
                            setFeeAmount(0);
                            setFeeName("");
                          } else {
                            toast.warn("Please check your inputs");
                          }
                        }}
                        className="btn btn-success"
                      >
                        Add
                      </button>
                      <button
                        onClick={() => setShowModal(!showModal)}
                        className="btn btn-outline-dark ms-5"
                      >
                        Done
                      </button>
                    </>
                  </ModalBody>
                </Modal>
              )}
              <FormGroup>
                <label htmlFor="notes">Notes</label>
                <textarea
                  name="notes"
                  className="form-control"
                  {...register("notes")}
                ></textarea>
                {errors.notes && (
                  <span className="text-danger">Notes is required</span>
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="date_of_birth">Date of birth</label>
                <input
                  type="date"
                  data-date=""
                  data-date-format="MM/DD/YYYY"
                  className="form-control date-input"
                  placeholder="date placeholder"
                  max={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 18)
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  {...register("dob", { required: true })}
                />
                {errors.dob && (
                  <span className="text-danger">Date of birth is required</span>
                )}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label=" "
                    className="form-control h-50"
                    style={{ height: 40 }}
                    maxDate={maxDate}
                    open={openDob}
                    onOpen={() => {
                      setopenDob2(false);
                      setopenService(false);
                      setopenDob(!openDob);
                    }}
                    value={clientDob}
                    onChange={(newValue) => {
                      setclientDob(newValue);
                      setopenDob(!openDob);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider> */}

                {/* {clientDob === null && (
                  <span className="text-danger">Date of birth is required</span>
                )} */}
              </FormGroup>
              <FormGroup className="col-md-6">
                <label htmlFor="service_date">Service date</label>
                <input
                  type="date"
                  data-date=""
                  data-date-format="MM/DD/YYYY"
                  className="form-control date-input"
                  placeholder="date placeholder"
                  min={
                    new Date()
                      .toLocaleString("en-US", { timeZone: "America/Chicago" })
                      .split(" ")[0]
                  }
                  {...register("service_date", { required: true })}
                />
                {errors.service_date && (
                  <span className="text-danger">Service date is required</span>
                )}
                {/* <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label=" "
                      className="form-control h-50"
                      style={{ height: 40 }}
                      minDate={minDate}
                      open={openService}
                      onOpen={() => {
                        setopenDob(false);
                        setopenDob2(false);
                        setopenService(!openService);
                      }}
                      value={serviceDate}
                      onChange={(newValue) => {
                        setServiceDate(newValue);
                        setopenService(!openService);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div> */}
                {/* 
                {serviceDate === null && (
                  <span className="text-danger">Service date is required</span>
                )} */}
              </FormGroup>
            </Row>
            <hr />
            <h4 className="mb-4 text-info">Secondary Account Holder</h4>
            <Row className="row-cols-lg-2">
              <FormGroup>
                <label htmlFor="secondary_name">Name</label>
                <input
                  type="text"
                  name="secondary_name"
                  className="form-control"
                  {...register("secondary_name")}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="secondary_phone">Phone</label>
                <input
                  type="tel"
                  name="secondary_phone"
                  className="form-control"
                  onKeyDown={preventAlphabets}
                  {...register("secondary_phone")}
                />
              </FormGroup>
              <FormGroup>
                <div>
                  <label htmlFor="secondary_dob">Date Of Birth</label>
                </div>
                <input
                  type="date"
                  data-date-format="MM/DD/YYYY"
                  data-date=""
                  className="form-control date-input"
                  placeholder="date placeholder"
                  max={maxDate}
                  {...register("secondary_dob")}
                />
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label=" "
                    className="form-control"
                    maxDate={maxDate}
                    // minDate={maxDate}
                    value={client2Dob}
                    open={openDob2}
                    onOpen={() => {
                      setopenDob(false);
                      setopenService(false);
                      setopenDob2(!openDob2);
                    }}
                    onChange={(newValue) => {
                      setclient2Dob(newValue);
                      setopenDob2(!openDob2);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider> */}
              </FormGroup>
            </Row>
          </div>
          <hr className="my-4" />
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn w-sm btn-primary"
              id="delete-record"
              disabled={isLoading}
            >
              {isLoading ? <Spinner size={"sm"} /> : "Save"}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

AddLeadModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default AddLeadModal;
