import React from "react";
import { Button } from "reactstrap";

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className="page-item">
          <a className="page-link" onClick={prevPage} href="#">
            <i className="ri-arrow-left-s-fill"></i>
          </a>
        </li>
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`page-item ${currentPage == pgNumber ? "active" : ""} `}
          >
            <a
              onClick={() => setCurrentPage(pgNumber)}
              className="page-link"
              href="#"
            >
              {pgNumber}
            </a>
          </li>
        ))}
        <li className="page-item">
          <a className="page-link" onClick={nextPage} href="#">
            <i className="ri-arrow-right-s-fill"></i>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;

export const PaginationLeadClient = ({
  totalItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);
  const nPages = Math.ceil(totalItems / itemsPerPage);

  const nextPage = () => {
    if (currentPage < nPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <nav className="d-flex justify-content-between align-items-center gap-2">
      <Button
        outline
        onClick={prevPage}
        disabled={currentPage === 1}
        aria-label="Previous page"
      >
        <i className="ri-arrow-left-s-line" />
      </Button>
      <div className="text-sm font-medium">
        {startItem}-{endItem} of {totalItems}
      </div>
      <Button
        outline
        onClick={nextPage}
        disabled={currentPage === nPages}
        aria-label="Next page"
      >
        <i className="ri-arrow-right-s-line" />
      </Button>
    </nav>
  );
};
