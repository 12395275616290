import $ from "jquery";
import moment from "moment";

export const convertDateInput = () => {
  $("input[type='date']")
    .on("change", function () {
      const inputDate = moment(this.value, "YYYY-MM-DD", true);
      const formattedDate = inputDate.isValid()
        ? inputDate.format(this.getAttribute("data-date-format"))
        : "mm/dd/yyyy";

      this.setAttribute("data-date", formattedDate);
    })
    .trigger("change");
};
